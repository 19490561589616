export const menuItems = [
  {
    name: "why-camelo",
    label: "Why Camelo?",
    items: [
      {name: "why-camelo", label: "Why Choose Us?"},
      {name: "", label: "How It Works"},
      {name: "before-after", label: "Before and After"},
    ],
  },
  {
    name: "features",
    label: "Product",
    items: [
      {
        name: "features",
        label: "Product Overview",
        description: "A quick overall look of Camelo’s main features and benefits.",
        extraClass: "mi-icon mi-product-overview",
      },
      {
        name: "features/scheduling-software",
        label: "Scheduling Software",
        description: "Create cost-optimized schedules and share instant updates easily.",
        extraClass: "mi-icon mi-scheduling-software",
      },
      {
        name: "features/time-and-attendance",
        label: "Time Tracking Software",
        description: "Record accurate timesheets and attendance for accurate and seamless payroll.",
        extraClass: "mi-icon mi-timeclock",
      },
      {
        name: "features/workplace-communication",
        label: "Team Communication",
        description: "Free mobile instant messaging app for effective team communication.",
        extraClass: "mi-icon mi-communication",
      },
    ],
  },
  {
    name: "pricing",
    label: "Pricing",
  },
  {
    name: "https://blog.camelohq.com",
    label: "Blog",
    isExternal: true,
  },
];
